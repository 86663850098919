import React, { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";
import { toast } from "react-toastify";

export default function Form() {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const cratesRef = useRef();
  const locationRef = useRef();
  const helpRef = useRef();

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const createReq = async (evt) => {
    evt.preventDefault();
    const id = toast.loading("Submission in progress..");
    try {
      const cred = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        crates: cratesRef.current.value,
        location: locationRef.current.value,
        help: helpRef.current.value,
      };
      const res = await axios.post(`https://bakerhomes.io/api/v1/farm`, cred);
      if (res.data.code === 200) {
        toast.update(id, {
          render: "Request Submitted Successfull",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        nameRef.current.value = "";
        emailRef.current.value = "";
        phoneRef.current.value = "";
        cratesRef.current.value = "";
        locationRef.current.value = "";
        helpRef.current.value = "";
      }
    } catch (err) {
      toast.update(id, {
        render: `${err.response}`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
      });
    }
  };

  return (
    <div>
      <Header />
      <main>
        <section className="form-section">
          <div className="form-text1">
            Want to buy crates of our fresh tomatoes/Bell Pepper/Cucumber ?
          </div>

          <div className="form-text2">
            Fill this form, we will get back to you shortly
          </div>

          <form onSubmit={createReq}>
            <div className="form-box">
              <label className="label">Your Full Name</label>
              <input
                ref={nameRef}
                className="input"
                placeholder="Enter your full name"
                required
              />
            </div>

            <div className="form-box">
              <label className="label">Your Email Address (Optional)</label>
              <input
                ref={emailRef}
                className="input"
                placeholder="Enter your email address"
              />
            </div>

            <div className="form-box">
              <label className="label">Your Phone Nuumber</label>
              <input
                ref={phoneRef}
                className="input"
                placeholder="Enter your Phone Number"
                required
              />
            </div>

            <div className="form-box">
              <label className="label">Number of Crates</label>
              <input
                ref={cratesRef}
                className="input"
                placeholder="Enter Number of Crates"
                required
              />
            </div>

            <div className="form-box">
              <label className="label">Your Location</label>
              <input
                ref={locationRef}
                className="input"
                placeholder="Enter your Location"
                required
              />
            </div>

            <div className="form-box">
              <label className="label">How can we be of help to you?</label>
              <textarea ref={helpRef} className="tarea"></textarea>
            </div>

            <button className="form-button">Send message</button>
          </form>
        </section>
      </main>
      <Footer />
    </div>
  );
}
