import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Cowpea() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="cowpea-section1"></section>
        <section className="tomatoe-section3">
          <div className="tomatoes-text3">Cowpea</div>
          <div className="tomatoes-text4">
            We have planted Cowpeas on 18 hectares of farmland in Kuje, Abuja
            Nigeria. Our major aim is to keep increasing food supply in Nigeria
            and steadily delivering naturally grown farm produce to our
            consumers.
          </div>
        </section>
        <section className="tomatoe-section4">
          <div className="tomatoes-text5">Take a virtual tour</div>
          <div className="tomatoe-section4-inner">
            {/*  <img className="timg" src="./images/playv.svg" alt="play" /> */}
            <iframe
              title="play2"
              className="play2"
              width="100%"
              height="640"
              frameborder="0"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowfullscreen
              scrolling="no"
              src="https://kuula.co/share/collection/79btv?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
            ></iframe>
            {/*  <div className="tour-text">Take a video tour of property</div> */}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
