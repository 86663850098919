import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Bell() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="bell-section1"></section>
        <section className="tomatoe-section3">
          <div className="tomatoes-text3">Red Bell Peppers</div>
          <div className="tomatoes-text4">
            We have planted red bell peppers on 2 hectares of farmland in
            Abeokuta, Nigeria. Our goal is to provide urban areas with yummy
            farm produce and service at an affordable price. Our estimated
            harvest quantity is over 12,000 crates before Q2 of 2023
          </div>
        </section>
        <section className="tomatoe-section4">
          <div className="tomatoes-text5">Take a virtual tour</div>
          <div className="tomatoe-section4-inner">
            {/*  <img className="timg" src="./images/playv.svg" alt="play" /> */}
            <iframe
              title="play2"
              className="play2"
              width="100%"
              height="640"
              frameborder="0"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowfullscreen
              scrolling="no"
              src="https://kuula.co/share/collection/79btv?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
            ></iframe>
            {/*  <div className="tour-text">Take a video tour of property</div> */}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
