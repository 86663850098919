import React, { useEffect, useRef } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import axios from "axios";
import { toast } from "react-toastify";

export default function Contact() {
  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const messageRef = useRef();

  const createReq = async (evt) => {
    evt.preventDefault();
    const id = toast.loading("Submission in progress..");
    try {
      const cred = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        phone: phoneRef.current.value,
        message: messageRef.current.value,
      };
      const res = await axios.post(
        `https://bakerhomes.io/api/v1/contact`,
        cred
      );
      if (res.data.code === 200) {
        toast.update(id, {
          render: "Request Submitted Successfull",
          type: "success",
          isLoading: false,
          autoClose: 5000,
          closeButton: true,
        });

        nameRef.current.value = "";
        emailRef.current.value = "";
        phoneRef.current.value = "";
        messageRef.current.value = "";
      }
    } catch (err) {
      console.log(err);
      toast.update(id, {
        render: `${err.response}`,
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
      });
    }
  };

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="contact-section1">
          <div>
            <div className="contact-text1">Contact Us</div>
            <div className="contact-text2">
              We would be glad to respond to your enquiries or requests
            </div>
          </div>
          <img className="img7" src="./images/img7.png" alt="img7" />
        </section>
        <section className="contact-section2">
          <div className="contact-contact">
            <div className="contact-text3">For General Enquiries</div>
            <div className="contact-list">
              <img className="email" src="./images/email.svg" alt="email" />
              <div>
                <div className="contact-text4">Email Address</div>

                <div className="contact-text6">
                  <a
                    className="contact-text6"
                    href="mailto:scontact@bakerindustries.io"
                  >
                    contact@bakerindustries.io
                  </a>
                </div>
              </div>
              <div className="or">OR</div>
            </div>

            <div className="contact-list">
              <img className="phone" src="./images/phone.svg" alt="phone" />
              <div>
                <div className="contact-text4">Call our office</div>
                <div className="contact-text5">016344119</div>
              </div>
            </div>
          </div>
          <form onSubmit={createReq} className="contact-form">
            <div className="contact-text7">Send us a message</div>

            <div className="contact-box">
              <div className="contact-text8">Your Full Name</div>
              <input
                className="contact-input"
                placeholder="Enter your full name"
                required
                ref={nameRef}
              />
            </div>

            <div className="contact-box">
              <div className="contact-text8">Your Email Address</div>
              <input
                className="contact-input"
                placeholder="Enter your email address"
                required
                ref={emailRef}
              />
            </div>

            <div className="contact-box">
              <div className="contact-text8">Your Phone Nuumber</div>
              <input
                className="contact-input"
                placeholder="e.g 08167345894"
                required
                ref={phoneRef}
              />
            </div>

            <div className="contact-box">
              <div className="contact-text8">Your Message</div>
              <textarea
                ref={messageRef}
                required
                className="contact-textarea"
              ></textarea>
            </div>

            <button className="contact-button">Send message</button>
          </form>
        </section>
      </main>
      <Footer />
    </div>
  );
}
