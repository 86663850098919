import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Team() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="team-section1">
          <div className="team-text1">Meet The Team</div>
          <div className="team-text2">
            We have a team of experts who are committed to growing our farm
            produce using the best practices.
          </div>
          <img className="img4" src="./images/img4.png" alt="img4" />
        </section>
        <section className="team-section2">
          <div>
            <img className="img5" src="./images/img5.png" alt="img5" />
            <div className="team-text3">Folarin Bakare</div>
            <div className="team-text4">Founder/CEO</div>
          </div>

          <div>
            <img className="img5" src="./images/segun.png" alt="img5" />
            <div className="team-text3">Osundina Olusegun</div>
            <div className="team-text4">Project Manager</div>
          </div>

          <div>
            <img className="img5" src="./images/img6.png" alt="img6" />
            <div className="team-text3">Idris Odenike</div>
            <div className="team-text4">Farm Manager</div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
