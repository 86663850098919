import React, { useState } from "react";
import { NavLink } from "react-router-dom";

export default function Header() {
  const [tog, setTog] = useState(true);
  const toggle = () => {
    setTog(!tog);
  };
  return (
    <div className="header">
      <div className="header-inner">
        <NavLink to="/">
          <img className="logo" src="./images/logo.svg" alt="logo" />
        </NavLink>

        <div className={tog ? "nav animation2" : "nav animation1"}>
          <NavLink
            className={({ isActive }) =>
              isActive ? "activeStyle" : "header-text"
            }
            to="/"
          >
            Home
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "activeStyle" : "header-text"
            }
            to="/team"
          >
            Our Team
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive ? "activeStyle" : "header-text"
            }
            to="/farm"
          >
            Farm Produce
          </NavLink>

          <NavLink
            className={({ isActive }) =>
              isActive
                ? "activeStyle header-contact none1"
                : "header-text header-contact none1"
            }
            to="/contact"
          >
            Contact Us
          </NavLink>
        </div>
      </div>

      <NavLink
        className={({ isActive }) =>
          isActive
            ? "activeStyle header-contact none"
            : "header-text header-contact none"
        }
        to="/contact"
      >
        Contact Us
      </NavLink>
      <img onClick={toggle} src="./images/ham.svg" alt="ham" className="ham" />
    </div>
  );
}
