import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <img className="footer_bg2" src="./images/bg1.png" alt="bg1" />

      <section className="footer-inner">
        <div className="footer-inner1">
          <div className="footer-inner2">
            <Link to="/">
              <img className="logo2" src="./images/logo.svg" alt="logo2" />
            </Link>

            <div className="footer-text-list">
              <Link className="footer-text" to="/">
                Home
              </Link>
              <Link className="footer-text" to="/team">
                Our Team
              </Link>
              <Link className="footer-text" to="/farm">
                Farm Produce
              </Link>
              <Link className="footer-text" to="/contact">
                Contact Us
              </Link>
            </div>
          </div>
          <div>
            <Link className="" to="/contact">
              <div className="footer-text1">Follow us </div>
            </Link>

            <div className="footer-social">
              <a
                className="social"
                href="https://instagram.com/bakerversefarms?igshid=NTdlMDg3MTY="
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="logo2"
                  src="./images/instagram.svg"
                  alt="logo2"
                />
              </a>
              <a
                className="social"
                href="https://twitter.com/bakerind_"
                target="_blank"
                rel="noreferrer"
              >
                <img className="logo2" src="./images/twitter.svg" alt="logo2" />
              </a>

              <a
                className="social"
                href="https://www.linkedin.com/company/bakerversefarms/"
                target="_blank"
                rel="noreferrer"
              >
                <img className="logo2" src="./images/li.svg" alt="logo2" />
              </a>

              <a
                className="social"
                href="https://www.facebook.com/bakerversefarms"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="logo2"
                  src="./images/facebook.svg"
                  alt="logo2"
                />
              </a>
            </div>
          </div>
        </div>
        <hr className="hr" />
        <div className="footer-text2">
          {" "}
          &copy; 2023 Bakerverse Farms. All rights Reserved
        </div>
      </section>
    </div>
  );
}
