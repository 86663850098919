import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function Cucumber() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="cucumber-section1">
          <div className="cucumber-section1-inner">
            <div className="cucumber-text1">Cucumber</div>
            <div className="cucumber-text2">
              Our cucumbers are strategically planted on 3 hectares of farmland
              at Abeokuta, Nigeria. <br />
              <br />
              The demand for fresh cucumbers keep increasing which has made
              cucumber one of the most sought after farm produce in Nigeria.
            </div>
          </div>
          <img src="./images/cucu.png" alt="t1" />
        </section>
        <section className="tomatoe-section4">
          <div className="tomatoes-text5">Take a virtual tour</div>
          <div className="tomatoe-section4-inner">
            {/*  <img className="timg" src="./images/playv.svg" alt="play" /> */}
            <iframe
              title="play2"
              className="play2"
              width="100%"
              height="640"
              frameborder="0"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowfullscreen
              scrolling="no"
              src="https://kuula.co/share/collection/79btv?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
            ></iframe>
            {/*  <div className="tour-text">Take a video tour of property</div> */}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
