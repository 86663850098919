import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Home() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />

      <main>
        <section className="home-section1">
          <div className="home-section1-inner">
            <div>
              <span className="home-section1-text1">Fresh,</span>{" "}
              <span className="home-section1-text2">From </span>
            </div>
            <div className="home-section1-text2">Farm to dining</div>
            <div className="home-section1-text3">
              Buy naturally grown farm produce direct from our farms
            </div>
          </div>
          <div>
            <img className="img1" src="./images/img1.png" alt="img1" />
          </div>
        </section>
        <img className="bg1" src="./images/bg1.png" alt="bg1" />
        <section className="home-section2">
          <div>
            <div className="home-section1-text4">Eat Clean and Green.</div>
            <div className="home-section1-text5">
              At Bakerverse Farms, We believe there is a better way to
              Re-imagine farming. A more affordable, organic way, where we
              supply our customers with the freshest farm produce Well-Rooted in
              nature, we are passionately focused on delivering value from
              Business-to Business and Direct-to-Consumers
            </div>
          </div>
        </section>
        <section className="home-section3">
          <div>
            <img className="img2" src="./images/img2.png" alt="img2" />
          </div>
          <div className="home-section3-inner">
            <div className="home-section1-text7">Who We Are </div>
            <div className="home-section1-text6">
              Bakerverse Farm is owned by the parent company,{" "}
              <span style={{ color: "#0ECA0A" }}>
                Baker Industries Limited.
              </span>{" "}
              Launched in 2022, Our mission is to make it as easy as possible
              for everyone to have access to the goodness of nature anywhere in
              Nigeria.{" "}
            </div>
          </div>
        </section>
        <section className="home-section4">
          <div className="home-section1-text8">
            Why You Should Buy From Our Farms
          </div>
          <div className="home-section4-list">
            <div className="home-card">
              <div className="home-section1-text9">Eat Organic</div>
              <div className="home-section1-text10">
                Unlike farm produce planted with fertilizers, our farm produce
                are naturally grown and ripen. Our priority is meeting the needs
                of our customers by delivering freshly harvested farm produce to
                them.
              </div>
            </div>
            <div className="home-card">
              <div className="home-section1-text9">Improve Your Business</div>
              <div className="home-section1-text10">
                Our major goal is to add more value to your business by selling
                farm produce from our farms to you cheaply. You receive more
                quality than what you are paying for. Straight to plate goodness
                for your customers!
              </div>
            </div>
          </div>
        </section>
        <section className="home-section5">
          <div className="home-section5-inner">
            <div className="home-section1-text11">
              <span style={{ color: "#0ECA0A" }}>Buy </span> crates of our
              freshly harvested tomatoes
            </div>
            <div className="home-section1-text12">
              Click the “Place an order” button below , fill in your location
              and send us a request. We will respond in few minutes!
            </div>
            <Link to="/form">
              <button className="home-button">
                <img src="./images/plant.svg" alt="plant" />
                <span className="order">Place an order</span>
              </button>
            </Link>
          </div>
          <div>
            {" "}
            <img className="img3" src="./images/img3.png" alt="img3" />
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
