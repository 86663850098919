import React from "react";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

export default function NotFound() {
  const navigate = useNavigate();
  return (
    <div>
      <Header />

      <div className="notfound">
        <img className="char" src="./images/character.svg" alt="character" />
        <div className="notfound-text1">
          Sorry! We couldn’t find the page you were looking for
        </div>
        <div className="notfound-text2">
          The link you followed may be broken or we may have removed the page
        </div>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => navigate("/")}
          className="notfound-text3"
        >
          Go to homepage
        </div>
      </div>
    </div>
  );
}
