import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { Link } from "react-router-dom";

export default function Tomatoes() {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="tomato-section1">
          <div className="tomato-section1-inner">
            <div className="tomatoes-text1">Tomatoes</div>
            <div className="tomatoes-text2">
              Our tomatoes are grown locally and ripen naturally on the vine. We
              have hectares of land in Abeokuta, Nigeria, which we have planted
              tomatoes. We have harvested and sold about 2000 crates of fresh
              tomatoes from Sept to October, 2022. Our goal is to exceed
              expectations and harvest over 10,000 crates of tomatoes before
              quarter 4 of 2023.
            </div>
          </div>
          <img className="t1" src="./images/t1.png" alt="t1" />
        </section>

        <section className="tomatoe-section2">
          <img className="fruits" src="./images/t2.png" alt="t2" />
          <img className="fruits" src="./images/t3.png" alt="t3" />
          <img className="fruits" src="./images/t4.png" alt="t4" />
          <img className="fruits" src="./images/t5.png" alt="t5" />
        </section>
        <section className="tomatoe-section3">
          <div className="tomatoes-text3">Buy Fresh Tomatoes</div>
          <div className="tomatoes-text4">
            Ordering of our juicy tomatoes is easy! Send a request to us and we
            will deliver to all locations in Lagos depending on how many crates
            . You can also buy at the farm gate or pickup at Mile 12. Eating
            better is living better!
          </div>
          <Link to="/form">
            <button className="tomatoe-button">
              {" "}
              <img src="./images/plant.svg" alt="plant" />
              <span className="order">Place an order</span>
            </button>
          </Link>
        </section>
        <section className="tomatoe-section4">
          <div className="tomatoes-text5">Take a virtual tour</div>
          <div className="tomatoe-section4-inner">
            {/*  <img className="timg" src="./images/playv.svg" alt="play" /> */}
            <iframe
              title="play2"
              className="play2"
              width="100%"
              height="640"
              frameborder="0"
              allow="xr-spatial-tracking; gyroscope; accelerometer"
              allowfullscreen
              scrolling="no"
              src="https://kuula.co/share/collection/79btv?logo=1&info=1&fs=1&vr=0&sd=1&thumbs=1"
            ></iframe>
            {/*  <div className="tour-text">Take a video tour of property</div> */}
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
