import { Route, Routes, BrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import "./css/farm.css";
import "./css/team.css";
import "./css/contact.css";
import "./css/tomatoes.css";
import "./css/form.css";
import Team from "./pages/Team";
import Farm from "./pages/Farm";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Tomatoes from "./pages/Tomatoes";
import Bell from "./pages/Bell";
import Cucumber from "./pages/Cucumber";
import Cowpea from "./pages/Cowpea";
import Form from "./pages/Form";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/team" element={<Team />} />
        <Route exact path="/farm" element={<Farm />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/tomatoes" element={<Tomatoes />} />
        <Route exact path="/bell" element={<Bell />} />
        <Route exact path="/cucumber" element={<Cucumber />} />
        <Route exact path="/cowpea" element={<Cowpea />} />
        <Route exact path="/form" element={<Form />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <ToastContainer autoClose={15000} />
    </BrowserRouter>
  );
}

export default App;
