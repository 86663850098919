import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { useNavigate } from "react-router-dom";

export default function Farm() {
  const navigate = useNavigate();
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div>
      <Header />
      <main>
        <section className="farm-section1">
          <div className="farm-text1">Explore Our Farms</div>
          <div className="farm-text2">
            We have planted Tomatoes, Bell Peppers, Cucumbers, and Cowpeas
            across different locations in Nigeria
          </div>
        </section>
        <div className="bg2"></div>
        <section className="farm-section2">
          <div className="farm-section-inner">
            <div className="farm-text3">Tomatoes</div>
            <button
              onClick={() => navigate("/tomatoes")}
              className="farm-button"
            >
              Enter Farm
            </button>
          </div>
        </section>
        <section className="farm-section3">
          <div className="bell">
            <div className="farm-section3-inner">
              <div className="farm-text3">Red Bell Pepper</div>
              <button onClick={() => navigate("/bell")} className="farm-button">
                Enter Farm
              </button>
            </div>
          </div>
          <div className="cucumber">
            <div className="farm-section3-inner">
              <div className="farm-text3">Cucumber</div>
              <button
                onClick={() => navigate("/cucumber")}
                className="farm-button"
              >
                Enter Farm
              </button>
            </div>
          </div>
        </section>
        <section className="farm-section4">
          <div className="farm-section-inner">
            <div className="farm-text3">Cowpea</div>
            <button onClick={() => navigate("/cowpea")} className="farm-button">
              Enter Farm
            </button>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
